import React from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { Logo, StoreLinks } from "../components";

const Recruitment: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zapyouout" />
        <meta
          name="twitter:title"
          content="Recruitment Title"
        />
        <meta
          name="twitter:description"
          content="Recruitment Details"
        />
        <meta
          name="twitter:image"
          content="https://pbs.twimg.com/card_img/1844084392458915840/uOpf73Vj?format=jpg&name=4096x4096"
        />
      </Helmet>
      <Box
        component="main"
        sx={{
          maxWidth: "510px",
          ml: "auto",
          mr: "auto",
          mt: 10,
        }}
      >
        <Logo />
        <StoreLinks />
      </Box>
    </>
  );
};

export default Recruitment;
